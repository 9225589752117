<template>
  <el-form label-width="auto">
    <el-form-item label="题目名称">
      <el-input> </el-input>
    </el-form-item>
    <el-form-item label="题目描述">
      <el-input> </el-input>
    </el-form-item>
    <el-form-item label="输入格式描述">
      <el-input type="textarea"> </el-input>
    </el-form-item>
    <el-form-item label="输出格式描述">
      <el-input type="textarea"> </el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {};
</script>
